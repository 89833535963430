// Expected behaviour of Series, Y-axis label and Legend in different modes
/*
* Note that edit RCO mode and edit Learning mode can be enabled at the same time. RCO mode
* overrides learning mode because it is more specific.
*
* Series + Y-axis   Temperature   RMS       Trendline   RCO
* Default/Initial   √             √~        √           x
* Add RMS alarm     √             √~        √           x
* Add TMP alarm     √             √~        √           x
* Edit RCO          x             √^        √           √
* Edit learning     √             √~        √           x
* Edit axis         √             √~        √           x
*
* Trendline has no Y-axis label, only Series and Legend.
* ~All screens except RCO use RMS filtered/selected data i.e. rms tag for v2.1 or rms2 tag for v3.x.
* ^Edit RCO uses RMS unfiltered i.e. rms tag AKA mms.
*
* Legend Selected   Temperature   RMS       Trendline   RCO
* Default/Initial   √             √         x`          N/A
* Add RMS alarm     x             √         x`          N/A
* Add TMP alarm     √             x         x`          N/A
* Edit RCO          N/A           √         x`          √
* Edit learning     √             √         x`          N/A
* Edit axis         √             √         x`          N/A
*
* `Selecting Trendline, de-selects Temperature.
*/

export const initialSelectRmsTrendline = false;
const baseDisplay = {
  displayTemperatureData: true,
  displayRmsData: false,
  displayRmsSelectedData: true,
  displayRunningCutoffData: false,
};
const baseSelect = {
  selectRco: false,
};
const commonSelect = {
  selectTemperature: true,
  selectRms: true,
};
const initialDisplayState = {
  ...baseDisplay,
  ...baseSelect,
  ...commonSelect,
};
const rmsDisplayState = {
  ...baseDisplay,
  ...baseSelect,
  selectTemperature: false,
  selectRms: true,
};
const tmpDisplayState = {
  ...baseDisplay,
  ...baseSelect,
  selectTemperature: true,
  selectRms: false,
};
const rcoDisplayState = {
  displayTemperatureData: false,
  displayRmsData: true,
  displayRmsSelectedData: false,
  displayRunningCutoffData: true,
  selectTemperature: false,
  selectRms: true,
  selectRco: true,
};
const learningDisplayState = {
  ...baseDisplay,
  ...baseSelect,
  ...commonSelect,
};
const axisDisplayState = {
  ...baseDisplay,
  ...baseSelect,
  ...commonSelect,
};
export const initialDisplayModeStates = {
  initialDisplayState: initialDisplayState,
  rmsDisplayState: rmsDisplayState,
  tmpDisplayState: tmpDisplayState,
  rcoDisplayState: rcoDisplayState,
  learningDisplayState: learningDisplayState,
  axisDisplayState: axisDisplayState,
};
