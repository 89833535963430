import React, { Component, Fragment } from 'react';
import { Row, Col, Form, Button, Container, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { IoIosWarning } from 'react-icons/io';
import withNavigationUserProps from './withNavigationUserProps';
import { withTranslation } from 'react-i18next';
import history from '../../../history';
import { fetchOrganisations } from '../../organisation/actions';
import { getFormValues } from '../../../lib/utils';
import Title from '../../../components/Title';
import LoadingSpinner from '../../../components/LoadingSpinner';
import NotFound from '../../../components/NotFound';
import UserAlarmSubscriptions from './UserAlarmSubscriptions';
import MFAManager from './MFAManager';
import GetConfirmationOtp from './GetConfirmationOtp';

import {
  fetchUser,
  fetchUserWithId,
  fetchUserTypeOptions,
  fetchUserPreferences,
  fetchUserDevices,
  submitUserDetails,
  submitUserWithIdDetails,
  submitNewUserDetails,
  submitUserPreferences,
  updateUserSubscriptions,
  getConfirmOTP,
} from './../actions';
import { fetchNotificationOptions } from '../../notification/actions';
import { getNotificationOptions } from '../../notification/selectors';

import {
  userPreferenceModels,
  mfaPreferenceModels,
  getUser,
  getUserTags,
  isUserEditable,
  getUserSubscriptions,
  isSuperAdmin as getIsSuperAdmin,
  getUserToken,
} from '../selectors';

import {
  getTimezoneDescriptionFull,
  getTimezoneOffset,
} from '../../../components/values/Timezone';

import { LanguageOptions } from '../../../components/Internationalisation';
import { getCurrentOrganisationHasProductCode } from '../../organisation/selectors';

const defaultUserType = 'User';

class EditUser extends Component {

  constructor(props) {
    super(props);
    const {
      userToEdit: { user_type = defaultUserType } = {},
      userTags,
    } = this.props;
    this.state = {
      fetchingUser: true,
      currentUserType: user_type,
      currentLanguageChoice: userTags['preferences:language'] || 'en-AU',
      mfaDelivery: '',
      mfaEnabled: false,
      mfaSmsConfirmed: false,
      mfaSaved: true,
      submit: {},
      submitting: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelivery = this.handleDelivery.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
  }

  handleDelivery(e) {
    const {
      mfaSmsConfirmed,
    } = this.props;
    this.setState({ mfaDelivery: e.target.value });
    this.setState( { mfaSaved: false } );
    if (!mfaSmsConfirmed) {
      this.setState({mfaSmsConfirmed: false});
    }
  }

  handleEnabled(e) {
    this.setState( { mfaEnabled: e.target.checked });
  }

  // fetch most recent details of the user
  fetchUserDetails = async () => {
    const {
      fetchUser,
      fetchUserPreferences,
      fetchUserWithId,
      fetchUserDevices,
      userIsSelf,
      userToEdit,
      userToEditId,
    } = this.props;
    this.setState({ fetchingUser: true });
    // fetch self
    if (userIsSelf) {
      await Promise.all([
        fetchUser(),
        userToEdit && userToEdit.id && fetchUserPreferences(userToEdit),
      ]);
    }
    // or fetch other user
    else if (userToEditId) {
      try {
        const response = await fetchUserWithId(userToEdit || { id: userToEditId });
        fetchUserPreferences({id: userToEditId});
        // if the user is a User then fetch their devices list
        if (response && response.data && response.data.user_type === 'User') {
          fetchUserDevices(userToEdit || { id: userToEditId });
        }
      }
      catch(e) {
        // user probably does not have access to this user
      }
    }
    this.setState({ fetchingUser: false });
  };

  componentDidMount() {
    const {
      fetchOrganisations,
      fetchUserTypeOptions,
      fetchNotificationOptions,
      mfaEnabled,
      mfaDelivery,
      mfaSmsConfirmed,
    } = this.props;
    fetchOrganisations();
    fetchUserTypeOptions();
    fetchNotificationOptions();
    this.fetchUserDetails();
    this.setState({ mfaEnabled, mfaDelivery, mfaSmsConfirmed });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      userToEditId,
      userToEdit: { user_type = defaultUserType } = {},
      userTags,
      mfaSmsConfirmed,
      mfaDelivery,
    } = this.props;
    const {
      userToEdit: { user_type: prev_user_type = defaultUserType } = {},
    } = prevProps;
    // update user type state if necessary
    // case: user's user_type from API was different than kept in redux
    if ((prev_user_type !== user_type) && (prevState.currentUserType !== user_type)) {
      this.setState({ currentUserType: user_type });
    }
    // reset submit form if the user is changed
    if (prevProps.userToEditId !== userToEditId) {
      this.setState({ submit: {}, currentUserType: user_type });
      this.fetchUserDetails();
    }
    if (prevProps.userTags['preferences:language'] !== userTags['preferences:language']) {
      this.setState({ currentLanguageChoice: userTags['preferences:language'] });
    }
    if (mfaSmsConfirmed !== prevProps.mfaSmsConfirmed) {
      this.setState({ mfaSmsConfirmed });
      if (mfaSmsConfirmed) this.setState({ mfaDelivery: mfaDelivery });
    }
  }
  // Get user's subscription status(true or false) by a given channel object.
  // It can be a channel by the given channelId, escalation or a subchannel by the given channelId, subChannelId and escalation
  getUserSubscriptionByChannel(channels, channelObj = {}) {
    // channelObj is an object with the keys of channelId, escalation and subChannelId(optional)
    const { channel_id, escalation, sub_channel_id } = channelObj;
    if(channel_id === undefined || escalation === undefined || !channels) return;

    const channel = channels.find(ch => ch.channel_id === channel_id && ch.escalation === escalation);
    if(sub_channel_id === undefined || (channel?.sub_channels || []).length === 0 ) return channel?.subscribed;
    const subChannel = channel.sub_channels.find(ch => ch.sub_channel_id === sub_channel_id);
    return subChannel?.subscribed;
  }

  handleSubmit(e) {
    e.preventDefault();
    const formValues = getFormValues(e);
    const {
      submitUserDetails,
      submitUserWithIdDetails,
      submitNewUserDetails,
      submitUserPreferences,
      updateUserSubscriptions,
      userToEdit = {},
      userIsSelf,
      userIsNew,
      fetchUserWithId,
      subscriptionChannels,
      userToken,
      getConfirmOTP,
    } = this.props;

    // transform organisation ids
    if (formValues.organisation_ids && formValues.organisation_ids.length) {
      formValues.organisation_ids = formValues.organisation_ids.map(id => parseInt(id));
    }

    const submittedAt = Date.now();
    this.setState({ submit: { submittedAt }, submitting: true });

    // todo: put use a form library to handle this, this is a workaround
    // it transforms { "orgs[4]": true, "orgs[5]": false } to { "orgs": [4] }
    // pick out array fields from the payload and attach them as arrays
    const checkboxInputNameRegex = /^(\w+)\[(\d+)\]$/;
    Object.entries(formValues).forEach(([key, value]) => {
      // get the identifiers from the name format
      const [match, name, id] = key.match(checkboxInputNameRegex) || [];
      if (match) {
        if (value) {
          // initialise as array if not already an array
          formValues[name] = formValues[name] || [];
          // remove this key from the main formValues payload
          formValues[name].push(parseInt(id));
        }
        delete formValues[key];
      }
    });

    const preferenceForm = {};
    // todo: put use a form library to handle this, this is a workaround
    // strip out the user preferences
    const preferenceInputNameRegex = /^(preferences:\w+)$/;
    const preferenceNotificationsRegex = /^(preferences:notifications)_(\w+)$/i;
    Object.entries(formValues).forEach(([key, value]) => {
      // get the identifiers from the name format
      const [match, name] = key.match(preferenceInputNameRegex) || [];
      const [matchNotification, notificationKey, notificationOption] = key.match(preferenceNotificationsRegex) || [];
      if (matchNotification && notificationKey === 'preferences:notifications') {
        if(!preferenceForm[notificationKey]) preferenceForm[notificationKey] = [];
        if(!value) preferenceForm[notificationKey].push(notificationOption);
        // For notification preferences, the options posted are actually what users did NOT select. It is to tell API not to send these notifications despite being named "preferences:notifications"
        delete formValues[key];
      } else if (match) {
        if (value !== undefined) {
          preferenceForm[`${name}`] = value;
        }
        // remove this key from the main formValues payload
        delete formValues[key];
      }
    });

    const subscriptionData = [];
    const subscriptionRegex = /^(channel-)(\d+).(\d+)(-escalation-)(\d+)$/;
    Object.entries(formValues).forEach(([key, value]) => {
      // eslint-disable-next-line
      const [match, channelKey, channelId, subChannelId, escalationKey, escalation] = key.match(subscriptionRegex) || [];
      if(match && channelId && subChannelId && escalation) {
        const data = {
          channel_id: +channelId,
          escalation: !!(+escalation),
          subscribed: value,
        };
        if(subChannelId !== '0') data.sub_channel_id = +subChannelId;
        const subscribed = this.getUserSubscriptionByChannel(subscriptionChannels, data);
        if(subscribed !== data.subscribed) subscriptionData.push(data);
        delete formValues[key];
      }
    });

    // submit form then handle feedback
    userToEdit && (
      userToEdit.id
        ? userIsSelf
          ? submitUserDetails(userToEdit, formValues)
          : submitUserWithIdDetails(userToEdit, formValues)
        : submitNewUserDetails(formValues)
    )
      .then(async response => {

        if (formValues.mfa_delivery === 'sms') {
          getConfirmOTP(userToken);
          this.setState({ mfaSaved: true });
        }

        if (Object.keys(preferenceForm).length) {
          try {
            await submitUserPreferences(userToEdit, preferenceForm);
          }
          catch (err) {
            // allow user edit to pass even if edit preferences fails
            // they should get a toast
          }
        }
        if(subscriptionData.length) {
          await updateUserSubscriptions(userToEdit, {subscriptions: subscriptionData});
        }

        if (userIsNew && response && response.headers && response.headers.location) {
          const matches = `${response.headers.location}`.match(/(\d+)$/);
          if (matches && matches[1]) {
            await fetchUserWithId({ id: matches[1] });
            history.push(`/users/${matches[1]}`);
          }
          else {
            history.push('/users/admin');
          }
        }
        else {
          // refetch the new user data
          this.fetchUserDetails();
          // if still displaying the same submission then update with success
          this.setState(({ submit }) => {
            if (submit.submittedAt === submittedAt) {
              return { submit: { succeededAt: new Date() } };
            }
          });
        }
      })
      .catch((error) => {
        // if still displaying the same submission then update with failure
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { error: error.message || 'Error' } };
          }
        });
      }).finally(() => {
        this.setState({submitting: false});
      });
  }

  render() {
    const {
      userIsEditable = false,
      userToEdit: userObject = {},
      userTags = {},
      t,
      hasI18nFeature,
      isSuperAdmin,
      mfaEnabled,
      hasMfaFeature,
    } = this.props;

    const { fetchingUser, currentLanguageChoice } = this.state;

    if (!userIsEditable && !fetchingUser) {
      return (
        <NotFound />
      );
    }

    return (
      <Container>
        {!userObject || !userIsEditable ? <LoadingSpinner /> : <Form
          id="edit-user-form"
          className="form-container"
          onSubmit={ this.handleSubmit }
        >
          <Title title={t('user.Email_sms_preferences')} loading={false} />
          <Form.Group controlId="form__edit_user--receive_daily_email">
            <Form.Check
              key={`receive_daily_email-${userObject.receive_daily_email}`}
              type="checkbox"
              label={t('user.Daily_Update_Email')}
              name="receive_daily_email"
              defaultChecked={userObject.receive_daily_email}
            />
          </Form.Group>
          <Form.Group controlId="form__edit_user--receive_alert_email">
            <Form.Check
              key={`receive_alert_email-${userObject.receive_alert_email}`}
              type="checkbox"
              label={t('user.Alarm_Channel_Notifications_via_Email')}
              name="receive_alert_email"
              defaultChecked={userObject.receive_alert_email}
            />
          </Form.Group>
          <Form.Group controlId="form__edit_user--receive_alert_sms">
            <Form.Check
              key={`receive_alert_sms-${userObject.receive_alert_sms}`}
              type="checkbox"
              label={t('user.Alarm_Channel_Notifications_via_SMS')}
              name="receive_alert_sms"
              defaultChecked={userObject.receive_alert_sms}
            />
          </Form.Group>
          <Form.Group controlId="form__edit_user--mentions_via_email">
            <Form.Check
              key="mentions_via_email"
              type="checkbox"
              label={t('user.Mentions_via_Email')}
              name="preferences:email_mentions"
              defaultChecked={userTags['preferences:email_mentions']}
            />
          </Form.Group>

          {this.props.notificationOptions?.length > 0 && <Fragment>
            <br />
            <Title title={t('user.Notification_centre_preferences')} />
            {this.props.notificationOptions.map(({flag, description}) => {
              return (
                <Form.Group key={flag} controlId={`form__notification_centre--${flag}`}>
                  <Form.Check
                    type="checkbox"
                    label={t(`user.${description.replaceAll(' ', '_')}`)}
                    name={`preferences:notifications_${flag}`}
                    defaultChecked={ !userTags['preferences:notifications'] || !(userTags['preferences:notifications']||[]).includes(flag)}
                  />
                </Form.Group>
              );
            })}
          </Fragment>}

          <Fragment>
            <br/>
            <Title title={t('user.User_preferences')} loading={false} />
            {hasMfaFeature && mfaPreferenceModels.map(({key, options}) => {
              return (
                <Form.Group as={Row} key={key}>
                  <Form.Label column sm="2" xs="4">
                    {t('components.user.common.multi-factor-authentication')}
                  </Form.Label>
                  <Col sm="8" xs="6">
                    <Form.Check
                      key="mfa_enabled"
                      type="checkbox"
                      label={t('enabled')}
                      onChange={this.handleEnabled}
                      name="mfa_enabled"
                      defaultChecked={mfaEnabled}
                    />
                    {this.state.mfaEnabled && options.map(({title, value, isDefaultOption}) => (
                      <Form.Check
                        key={value}
                        id={`${key}-${value}`}
                        type="radio"
                        label={
                          `${t([`components.user.edit-user-preferences.${value}`])} ${value === 'email' ?
                            userObject.email : (userObject.mobile_phone || t('no-records'))}`
                        }
                        onChange={this.handleDelivery}
                        value={value}
                        disabled={value === 'sms' && userObject.mobile_phone === null}
                        name={`mfa_${key}`}
                        defaultChecked={this.state.mfaDelivery === value || (
                          !!isDefaultOption && !options.find(({value}) => {
                            if (value === 'sms' && userObject.mobile_phone === null) {
                              return false;
                            }
                            return this.state.mfaDelivery === value;
                          })
                        )}
                      />
                    ))}
                    {
                      this.state.mfaEnabled &&
                      this.state.mfaDelivery === 'sms' &&
                      !this.state.mfaSmsConfirmed &&
                      <Fragment>
                        < br/>
                        <Alert variant="warning">
                          <IoIosWarning size="1.4em" />
                          {t('components.user.edit-user-preferences.using-sms')}
                        </Alert>
                      </Fragment>}
                    {
                      this.state.mfaEnabled &&
                      this.state.mfaDelivery === 'sms' &&
                      !this.state.mfaSmsConfirmed &&
                      this.state.mfaSaved &&
                      <Fragment>
                        <GetConfirmationOtp />
                        {" "}
                        <MFAManager />
                      </Fragment>}
                  </Col>
                </Form.Group>
              );
            })}
            {userPreferenceModels.map(({ key, title, options }) => {
              const userPreferenceValue = userTags[`preferences:${key}`];
              return (
                <Form.Group as={Row} key={key}>
                  <Form.Label column sm="2" xs="4">
                    {t(`user.${title.replaceAll(' ', '_')}`)}
                  </Form.Label>
                  <Col sm="8" xs="6">
                    {options.map(({ title, description, value, isDefaultOption }) => (
                      // note: this can easily become a UserPreferenceRadioInput component later
                      // if more input types are needed and this starts to become verbose
                      <Form.Check
                        key={value}
                        id={`${key}-${value}`}
                        type="radio"
                        label={`${t(title)} (${t(description)})`}
                        value={value}
                        name={`preferences:${key}`}
                        defaultChecked={userPreferenceValue === value || (
                          // if this is default option
                          // flag this option as true if no matching preference is found
                          !!isDefaultOption && !options.find(({ value }) => {
                            return userPreferenceValue === value;
                          })
                        )}
                      />
                    ))}
                  </Col>
                </Form.Group>
              );
            })}
            {(hasI18nFeature || isSuperAdmin) && <Form.Group as={Row} key={"preferences:language"}>
              <Form.Label column sm="2" xs="4">
                {t('user.Language')}
              </Form.Label>
              <Col sm="8" xs="6">
                <Form.Control
                  as="select"
                  value={currentLanguageChoice}
                  name="preferences:language"
                  onChange={e => this.setState({
                    currentLanguageChoice: e.target.value
                  })}
                >
                  <LanguageOptions nameType={'longName'} />
                </Form.Control>
              </Col>
            </Form.Group>}
            <Form.Group as={Row} className="mb-0">
              <Form.Label column sm="2" xs="4">
                {t('user.Display_Timezone')}
              </Form.Label>
              <Col sm="8" xs="6">
                <Form.Control
                  plaintext={true}
                  readOnly={true}
                  key={`user_type-${getTimezoneDescriptionFull()}`}
                  defaultValue={ getTimezoneDescriptionFull() }
                />
                <Form.Text id={`user_type-${getTimezoneDescriptionFull()}_help`} className="text-muted">
                  {t('user.Timezone_description')}
                </Form.Text>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2" xs="4">
                {t('user.DateTime_Format')}
              </Form.Label>
              <Col sm="8" xs="6">
                <Form.Control
                  plaintext={true}
                  readOnly={true}
                  // show a date where it is impossible to confuse date with month
                  // or am for 24h time, eg. 13th day, 1pm
                  key={`user_type-${new Date('2020-01-13T13:00').toLocaleString()}`}
                  defaultValue={`Example: ${
                    new Date('2020-01-13T13:00').toLocaleString()
                  } (${getTimezoneOffset()})`}
                />
                <Form.Text id={`user_type-${new Date('2020-01-13T13:00').toLocaleString()}_help`} className="text-muted">
                  {t('user.Datetime_format_description')}{" "}
                  ({t('Currently')} "{Intl.DateTimeFormat().resolvedOptions().locale}").
                </Form.Text>
              </Col>
            </Form.Group>
          </Fragment>
          <Fragment>
            <UserAlarmSubscriptions user={userObject} />
          </Fragment>
          <Form.Group className="text-right mb-chat-widget">
            <Button variant="success" type="submit" size="lg" disabled={this.state.submitting}>
              {t('Update')}
            </Button>
          </Form.Group>
        </Form>}
      </Container>
    );
  }
}

const mapStateToProps = (state, { userIsSelf, userIsNew, userId }) => {
  const userToEdit = getUser(state, userId);
  return {
    userIsNew,
    userIsSelf,
    userIsEditable: isUserEditable(state, { userIsSelf, userIsNew, userId }),
    userToEditId: userId,
    userToEdit,
    userTags: getUserTags(state, userId),
    mfaEnabled: userToEdit.mfa_enabled,
    mfaSmsConfirmed: userToEdit.mfa_sms_confirmed,
    mfaDelivery: userToEdit.mfa_delivery,
    userToken: getUserToken(state),
    notificationOptions: getNotificationOptions(state),
    subscriptionChannels: getUserSubscriptions(state, userId),
    hasI18nFeature: getCurrentOrganisationHasProductCode(state, 'i18n'),
    isSuperAdmin: getIsSuperAdmin(state),
    hasMfaFeature: getCurrentOrganisationHasProductCode(state, 'mfa'),
  };
};

const mapDispatchToProps = {
  fetchUser,
  fetchUserWithId,
  fetchOrganisations,
  fetchUserPreferences,
  fetchUserDevices,
  fetchUserTypeOptions,
  fetchNotificationOptions,
  submitUserDetails,
  submitUserWithIdDetails,
  submitNewUserDetails,
  submitUserPreferences,
  updateUserSubscriptions,
  getConfirmOTP,
};

export default withNavigationUserProps(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditUser))
);
