import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { getAlarmDevice } from '../selectors';

import ControlledCarousel from './Carousel';

function AlarmPossibleCausesImages({
  alarm,
  handleChildClick,
}) {

  return (
    <Row>
      <Col xs={12}>
        <ControlledCarousel
          handleChildClick={handleChildClick}
          images={alarm.possible_causes}
        />
      </Col>
    </Row>
  );
}

const mapStateToProps = (state, { alarmId }) => {
  const device = getAlarmDevice(state, alarmId);
  return {
    deviceId: device?.id,
  };
};

export default connect(mapStateToProps, null)(AlarmPossibleCausesImages);
