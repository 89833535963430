import { WIFI_ONLY_FIT_MACHINES, SENSOR_TYPE } from './constants';
import moment from 'moment';

// Images
import fmPlaceholder from '../../images/fmPlaceholder.png';
import exPlaceholder from '../../images/fmExPlaceholder.png';
import fpPlaceholder from '../../images/fpPlaceholder.png';

export function getDraftFromProps({ navigation, devices }) {
  return { ...devices.drafts[navigation.state.params.draftIndex], draftIndex: navigation.state.params.draftIndex };
}

// allow a helper to fetch from the device object
export function getPlaceholderImageForDevice(device) {
  if(device?.sensor_type === 'fitpower') return fpPlaceholder;
  return device && `${device.fitmachine_type}`.match(/\bEX\b/)
    ? exPlaceholder
    // return regular as default
    : fmPlaceholder;
}

export function cleanTemplate(device) {
  const template = { ...device };
  delete template.images;
  delete template.installation_date;
  delete template.note;
  delete template.serial;
  delete template.firmwareVersion;
  delete template.wifiProfiles;
  delete template.wifiConnectivity;
  return template;
}

export function formatDeviceName(device) {
  const { site_name, sub_area_name, equipment_name } = device || {};

  const location = `${site_name || ''}${sub_area_name ? ', ' : ''}${sub_area_name || ''}`;
  const name = equipment_name || 'Unnamed device';

  return `${location || ''}${location ? ': ' : ''}${name}`;
}

/**
 * Maps a device from the Platform API structure to
 * the form the app expects.
 * @param {object} device The device to map
 */
export function mapDeviceFromApi(device) {
  return {
    id: device.id,
    archived: device.archived,
    serial: device.fitmachine_serial,
    bt_address: device.bt_address,
    latitude: device.latitude,
    longitude: device.longitude,
    site_name: device.site_name,
    sub_area_name: device.site_subarea_name,
    installation_date: !!device.fitmachine_install_date && moment(device.fitmachine_install_date),
    calibration_start: !!device.fitmachine_onboard_date && moment(device.fitmachine_onboard_date),
    note: device.note,
    iso_class: device.equipment_iso_class,
    equipment_name: device.equipment_name,
    equipment_number: device.equipment_number,
    equipment_brand: device.equipment_brand,
    equipment_model: device.equipment_model,
    equipment_capacity: device.equipment_capacity,
    max_rpm: !!device.equipment_max_rpm && Number(device.equipment_max_rpm),
    power_rating: device.equipment_power_rating,
    running: device.equipment_running,
    running_cutoff: device.rms_running_cutoff,
    confined_space: device.confined_space,
    variable_speed_drive: device.variable_speed_drive,
    equipment_type: device.equipment_type,
    organisation_name: device.organisation_name,
    fitmachine_type: device.fitmachine_type,

    // dashboard only parameters
    created_at: device.created_at,
    organisation_id: device.organisation_id,
    onboarded_by: device.onboarded_by,
    organisation_sub_domain: device.organisation_sub_domain,
    onboarded_name: device.onboarded_name,
    mute_advisory_for: device.mute_advisory_for,
    battery_voltage: device.battery_voltage,
    battery_score: device.battery_score,
    wifi_signal: device.wifi_signal,
    wifi_score: device.wifi_score,
    rms: device.rms,
    rms2: device.rms2,
    baseline_temperature: device.baseline_temperature,
    temperature: device.temperature,
    calibration: device.calibration,
    condition_indicated: device.condition_indicated,
    condition_overall: device.condition_overall,
    condition_vibration: device.condition_vibration,
    condition_temperature: device.condition_temperature,
    utilisation_month: device.utilisation_month,
    power_usage_month: device.power_usage_month,
    rate_overall: device.rate_overall,
    fitmachine_last_heard: device.fitmachine_last_heard,
    fitmachine_last_sample_date: device.fitmachine_last_sample_date,
    is_calibrating: device.is_calibrating,
    tags: { ...device.tags },
    product_codes: device.product_codes,
    status_summary: device.status_summary,
    status_caption: device.status_summary?.caption || '',
    fmconfig: device.fmconfig,
    role: device.role,
    state: device.state,
    sensor_type: device.sensor_type,
    current: device.current,
    related_ids: device.related_ids,
  };
}

/**
 * This function takes a WiFi MAC address and returns whether it's
 * found in the hardcoded list of WiFi-only FitMachines
 * @param {string} serial The WiFi MAC address in question
 * @returns {boolean}
 */
export function isWifiOnlyFitMachine(serial) {
  const firstThreeOctets = serial.substring(0, 8);
  const fourthOctet = serial.substring(9, 11);
  const lastTwoOctets = serial.substring(12);

  const page = WIFI_ONLY_FIT_MACHINES[firstThreeOctets];
  if (page) {
    const section = page[fourthOctet];
    if (section) {
      return !!section.find(o => o === lastTwoOctets);
    }
  }
  return false;
}

/**
 * Return the sensor name.
 * If sensor type is 'fitpower' return 'FitPower',
 * If sensor type is 'fitmachine' return 'FitMachine',
 * Otherwise, just return 'sensor'.
 * @param {object} device
 * @returns {string}
 */
export function getSensorName(device) {
  return device?.sensor_type === 'fitpower' ? 'FitPower' : device.sensor_type === 'fitmachine' ? 'FitMachine' : 'Sensor';
}

export function isFitPower(device) {
  return device?.sensor_type === SENSOR_TYPE.FITPOWER;
}

export function isFitMachine(device) {
  return device?.sensor_type === SENSOR_TYPE.FITMACHINE;
}

// Get the FitMachine major version of FitMachine only e.g. "V3", as the API returns
// major and minor e.g. "V3.2".
// Uses a single FM only.
export function getSensorVersion(device) {
  let version = null;
  if (device.fitmachine_type?.toLowerCase().startsWith('v')) { // Need to ignore EX and DEV
    version = parseInt(device.fitmachine_type.slice(1, 2));
  }
  return version;
}

// Choose the correct RMS value based on the FitMachine major version e.g. "V3".
// V3 uses rms2, V2 uses rms.
// Iterates over an array of samples which already have FM version.
export function addVibrationRms(sampleData) {
  for (const x in sampleData) {
    const sample = sampleData[x];
    setVibrationRms(sample);
  }
  return sampleData;
}

// Choose the correct RMS value based on the FitMachine major version e.g. "V3".
// V3 uses rms2, V2 uses rms.
// Uses a single FM only.
export function setVibrationRms(device) {
  const version = getSensorVersion(device);
  device.vibration_rms = null;
  if (version === 2) device.vibration_rms = device.rms;
  else if (version === 3) device.vibration_rms = device.rms2;
  return device;
}

// Add the FitMachine type to a sample.
// Iterates over an array of samples.
export function addSensorTypeToSamples(device, sampleData) {
  for (const x in sampleData) {
    addSensorTypeToSample(device, sampleData[x]);
  }
  return sampleData;
}

// Add the FitMachine type to a sample.
// Uses a single FM only.
export function addSensorTypeToSample(device, sample) {
  if (device) sample.fitmachine_type = device.fitmachine_type;
  return sample;
}

export function convertRunningSamplesToBars(samples) {
  // if running 1 -> 0 = 0
  // if running 1 -> 1 = 1
  // if running 0 -> 0 = 0
  // if running 0 -> 1 = 0
  // todo: Refactor this next week -> https://movusoz.atlassian.net/browse/DASH-1032
  // This is long and horrible and complicated, but I have nothing left in my brain right now. It's
  // tested, it's just ugly.
  if (samples.length < 2) { // We can't build a range with 0 or 1 samples. Sorry binary lovers.
    return [];
  }
  const barRanges = [];
  let thisChunk = {
    state: null,
    start: null,
    end: null,
  };
  let currentState = null;
  let nextState = null;
  let afterState = null;
  for (const [index, sample] of samples.entries()) {
    let nextOne = index + 1;
    const oneAfter = index + 2;
    if (oneAfter < samples.length) {
      const rangeStart = sample;
      const rangeEnd = samples[nextOne];
      const rangeAfter = samples[oneAfter];
      if (!thisChunk.start) thisChunk.start = rangeStart;
      currentState = rangeStart.equipment_running;
      nextState = rangeEnd.equipment_running;
      afterState = rangeAfter.equipment_running;
      if (currentState !== nextState) {
        if (!currentState && nextState) {
          if (!afterState) {
            thisChunk.state = false;
            thisChunk.end = null;
          } else {
            thisChunk.state = false;
            thisChunk.end = rangeEnd;
          }
        } else {
          if (thisChunk.state) thisChunk.end = rangeStart;
        }
        if (thisChunk.end) {
          if (!thisChunk.state) { // Only render the 'not running' range
            barRanges.push(thisChunk);
          }
          thisChunk = {
            state: null,
            start: thisChunk.end,
            end: null,
          };
        }
      } else {
        thisChunk.state = currentState;
      }
    } else if (nextOne < samples.length) {
      const rangePenultimate = sample;
      const rangeLast = samples[nextOne];
      currentState = rangePenultimate.equipment_running;
      nextState = rangeLast.equipment_running;
      if (currentState !== nextState) {
        if (!currentState && nextState) {
          // We don't know what the next one after is so must assume it's false
          thisChunk.state = false;
          thisChunk.end = rangeLast;
        } else {
          if (thisChunk.state) {
            thisChunk.end = rangePenultimate;
          } else {
            thisChunk.end = rangeLast;
          }
        }
      } else {
        thisChunk.end = rangeLast;
        if (!thisChunk.state) {
          thisChunk.state = rangeLast.equipment_running;
        }
      }
      nextOne = nextOne + 1;
      if (!thisChunk.state) { // Only render the 'not running' range
        barRanges.push(thisChunk);
      }
    }
  }
  return barRanges;
}
